<template>
  <div>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.full_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>
        <!-- Field: Designation -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Designation"
            label-for="user-designation"
          >
            <v-select
              v-model="userData.designation_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="designationOptions"
              :reduce="val => val.id"
              label="designation"
              :clearable="true"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="User Role"
            label-for="user-role"
          >
            <v-select
              v-model="userData.role_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.id"
              label="name"
              :clearable="true"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="updateAccount"
      >
        Save Changes
      </b-button>
    
    </b-form>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormCheckboxGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import useUsersList from '../list/useUsersList'
import store from '@/store'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    designationOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {

    updateAccount() {
      store.dispatch('app-user/updateAccount', this.userData)
        .then((response) => {
          if(response.data.status == 1){
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully added!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
                this.$emit('refetch-data')
          }
          else if(response.data.status == 0){
            if (response.data.message) {
              this.$swal({
                icon: 'error',
                title: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            
            }else{
              var errors = response.data.errors;
              this.$swal({
                  icon: 'error',
                  text: errors.join(', ' ),
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
            }
          }
        })
    }
  },


}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
