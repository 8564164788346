<template>
  <div>
  <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
  >
    <b-form
      @submit.prevent="handleSubmit(changePassword)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
              #default="validationContext"
              name="password"
              rules="required|password"
          >
            <b-form-group
              label="New Password"
              label-for="password"
            >
                <b-form-input
                  id="password"
                  v-model="form.password"
                  type="password"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <validation-provider
              #default="validationContext"
              name="Confirm Password"
              rules="required|password"
          >
            <b-form-group
              label="Confirm Password"
              label-for="confirm_password"
            >
                <b-form-input
                  id="confirm_password"
                  v-model="form.password_confirmation"
                  type="password"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      </b-row>
        <b-col class="mt-2">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback,
} from 'bootstrap-vue'

import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback, ValidationProvider, ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      password,
    }
  },
  methods: {

    changePassword() {
      store.dispatch('app-user/changePassword', this.form)
        .then((response) => {
          if(response.data.status == 1){
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully added!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
                this.$emit('refetch-data')
          }
          else if(response.data.status == 0){
            if (response.data.message) {
              this.$swal({
                icon: 'error',
                title: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            
            }else{
              var errors = response.data.errors;
              this.$swal({
                  icon: 'error',
                  text: errors.join(', ' ),
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
            }
          }
        })
    }
  },
  setup(props) {
    const form = {
      id: props.userData.id,
      password: "",
      password_confirmation: "",
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      form,
    }
  }
}
</script>

<style>

</style>
