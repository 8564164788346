<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="userData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <user-edit-tab-account
          :user-data="userData"
          :role-options="roleOptions"
          :designation-options="designationOptions"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <user-edit-tab-information
          :user-data-info.sync="userData" 
          @refetch-data="refetchData"
          class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Password -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Change Password</span>
        </template>
        <user-edit-tab-password
          :user-data.sync="userData" 
          @refetch-data="refetchData"
          class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Permission -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Change Permission</span>
        </template>
        <user-edit-tab-permission
          :user-data="userData" 
          @refetch-data="refetchData"
          :permissions-ids.sync="permission_ids"
          :permissions-data.sync="permissions"
          class="mt-2 pt-75" 
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabPassword from './UserEditTabPassword.vue'
import UserEditTabPermission from './UserEditTabPermission.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
    UserEditTabPermission,
  },

  setup() {
    const userData = ref(null)
    const permissions = ref([])
    const permission_ids = ref([])

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const getUserData = async () => {
      store.dispatch('app-user/editUser', { id: router.currentRoute.params.id })
        .then((response) => { 
            userData.value = response.data.data 
            permissions.value = response.data.permissions 
            permission_ids.value = response.data.permission_ids
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    }

    const getDesignationData = async () => {
      store.dispatch('app-user/fetchDesignationOption')
        .then((response) => {
          if(response.data.status == 1)
            designationOptions.value = response.data.data
      })
    }
    const getRoleData = async () => {
      store.dispatch('app-user/fetchRoleOption')
        .then((response) => {
          if(response.data.status == 1)
            roleOptions.value = response.data.data
      })
    }

    onMounted(getUserData)
    onMounted(getDesignationData)
    onMounted(getRoleData)

    const designationOptions = ref([])
    const roleOptions = ref([])

    const refetchData = async () => {getUserData()}

    return {
      userData,
      refetchData,
      permissions,
      permission_ids,
      roleOptions,
      designationOptions,
    }
  },
}
</script>

<style>

</style>
