<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Personal Information
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
      <b-form class="mt-1">
        <b-row>

          <!-- Field: Phone -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userDataInfo.phone"
              />
            </b-form-group>
          </b-col>

          <!-- Hourly Rate-->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Hourly Rate"
            >
              <b-form-group
                label="Hourly Rate ($)"
                label-for="hourly_rate"
              >
                <b-form-input
                  id="hourly_rate"
                  v-model.number="userDataInfo.hourly_rate"
                  step = "0.05" type="number"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Weekly Limit -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Weekly Limit"
            >
              <b-form-group
                label="Weekly Limit (Hour)"
                label-for="weekly_limit"
              >
                <b-form-input
                  id="weekly_limit"
                  v-model.number="userDataInfo.weekly_limit"
                  step = "0.05" type="number"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="update"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormInvalidFeedback
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userDataInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  methods: {

    update() {
      store.dispatch('app-user/updateUser', this.userDataInfo)
        .then((response) => {
          if(response.data.status == 1){
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully added!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
                this.$emit('refetch-data')
          }
          else if(response.data.status == 0){
            if (response.data.message) {
              this.$swal({
                icon: 'error',
                title: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            
            }else{
              var errors = response.data.errors;
              this.$swal({
                  icon: 'error',
                  text: errors.join(', ' ),
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
            }
          }
        })
    }
  },
  setup() {

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
