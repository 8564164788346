<template>
  <div>

    <!-- User Info: PERMISSION Fields -->
    <b-form>

      <!-- PERMISSION TABLE -->
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">User Permission</span>
          </b-card-title>
        </b-card-header>

        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="permission_ids"
          name="flavour-2"
          class="demo-inline-spacing"
        >
            <b-table
              striped
              responsive
              class="mb-0"
              :items="permissionsData"
            >
              <template #cell(subject)="data">
                {{ data.value }}
              </template>
              <template #cell()="data">
                <b-form-checkbox v-if="data.value" :checked="data.value" :value="data.value"/>
              </template>
            </b-table>
        </b-form-checkbox-group>
      </b-card>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="changePermission"
      >
        Save Changes
      </b-button>

    </b-form>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormCheckboxGroup
} from 'bootstrap-vue'

import store from '@/store'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    permissionsData: {
      type: Array,
      required: true,
    },
    permissionsIds: {
      type: Array,
    },
  },
  setup(props){
    const permission_ids = ref([])
    permission_ids.value = props.permissionsIds

    return { permission_ids }
  },
  methods: {

    changePermission() {
      store.dispatch('app-user/changePermission', {id: this.userData.id, permission_ids: this.permission_ids})
        .then((response) => {
          if(response.data.status == 1){
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully added!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
                this.$emit('refetch-data')
          }
          else if(response.data.status == 0){
            if (response.data.message) {
              this.$swal({
                icon: 'error',
                title: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            
            }else{
              var errors = response.data.errors;
              this.$swal({
                  icon: 'error',
                  text: errors.join(', ' ),
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
            }
          }
        })
    }
  }

}
</script>

